
$black: #222222;
$white: #fbf9f5;
$white--darker: #eeeeee;

$pure-black: #000000;
$pure-white: #ffffff;

$gray: #7d7d7d;
$gray--light: lighten($gray, 12%);
$gray--lighter: lighten($gray, 24%);
$gray--lightest: lighten($gray, 36%);
$gray--dark: #444444;


$primary: #d3c39d;
$primary--light: #e7dec9;
$primary--lighter: #f3eee3;
$primary--dark: #998b6b;
$primary--darker: #776437;
$primary--darkest: #463c25;


$secondary: #524656;

$red: #ef0717;
$error: #ef0717;

$green: #25811f;
$success: #25811f;


/*=============================================================================
    breakpoints
=============================================================================*/

$xl: 1400px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 480px;
$xxs: 380px;
$tiny: 320px;

@mixin media-max($bp) {
    @media (max-width: $bp) {
        @content;
    }
}

@mixin media-min($bp) {
    @media (min-width: ($bp + 1px)) {
        @content;
    }
}

/*==============================================================================
    mixins
==============================================================================*/
// $retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

@mixin retina {
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
        @content;
    }
}

//inline-block fix for ie7
@mixin ib {
    display: inline-block;
    zoom: 1; //triggers hasLayout
    *display: inline; //ignored by modern browser, therefore *
}

// placeholder styling
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}