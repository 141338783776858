/*=============================================================================
    icons
=============================================================================*/
.icon {
    @include ib;

    // background-size: 100%;
    // background-repeat: no-repeat;

    // transition: all 200ms ease;
}

a:hover,
button:hover {
    .icon-search {
        @extend .icon-search-hover;
    }

    .icon-search-sm {
        @extend .icon-search-sm-hover;
    }

    .icon-burger {
        @extend .icon-burger-hover;
    }

    .icon-twitter {
        @extend .icon-twitter-hover;
    }

    .icon-facebook {
        @extend .icon-facebook-hover;
    }

    .icon-rss {
        @extend .icon-rss-hover;
    }
}

.logo-vtedy:hover {
    @extend .logo-vtedy-hover;
}

// .icon-search {
//     width: 35px;
//     height: 35px;

//     background-image: url('../img/icons/icon-search.png');

//     @include retina {
//         background-image: url('../img/icons/icon-search@2x.png');
//     }
// }

// a:hover,
// button:hover {
//     .icon-search {
//         background-image: url('../img/icons/icon-search-hover.png');

//         @include retina {
//                 background-image: url('../img/icons/icon-search--hover@2x.png');
//         }
//     }
// }

// .icon-search-2 {
//     width: 25px;
//     height: 25px;

//     background-image: url('../img/icons/icon-search-2.png');

//     @include retina {
//         background-image: url('../img/icons/icon-search-2@2x.png');
//     }
// }

// a:hover,
// button:hover {
//     .icon-search-2 {
//         background-image: url('../img/icons/icon-search-2--hover.png');

//         @include retina {
//                 background-image: url('../img/icons/icon-search-2--hover@2x.png');
//         }
//     }
// }

// .icon-close {
//     width: 13px;
//     height: 13px;

//     background-image: url('../img/icons/icon-close.png');

//     @include retina {
//         background-image: url('../img/icons/icon-close@2x.png');
//     }
// }

// a:hover,
// button:hover {
//     .icon-close {
//         background-image: url('../img/icons/icon-close--hover.png');

//         @include retina {
//                 background-image: url('../img/icons/icon-close--hover@2x.png');
//         }
//     }
// }

// .icon-close-2 {
//     width: 17px;
//     height: 17px;

//     background-image: url('../img/icons/icon-close-2.png');

//     @include retina {
//         background-image: url('../img/icons/icon-close-2@2x.png');
//     }
// }

// a:hover,
// button:hover {
//     .icon-close-2 {
//         background-image: url('../img/icons/icon-close-2--hover.png');

//         @include retina {
//                 background-image: url('../img/icons/icon-close-2--hover@2x.png');
//         }
//     }
// }

// .icon-close-3 {
//     width: 12px;
//     height: 12px;

//     background-image: url('../img/icons/icon-close-3.png');

//     @include retina {
//         background-image: url('../img/icons/icon-close-3@2x.png');
//     }
// }

// .icon-close-4 {
//     width: 12px;
//     height: 12px;

//     background-image: url('../img/icons/icon-close-4.png');

//     @include retina {
//         background-image: url('../img/icons/icon-close-4@2x.png');
//     }
// }

// .icon-burger {
//     width: 29px;
//     height: 25px;

//     background-image: url('../img/icons/icon-burger.png');

//     @include retina {
//         background-image: url('../img/icons/icon-burger@2x.png');
//     }
// }

// a:hover,
// button:hover {
//     .icon-burger {
//         background-image: url('../img/icons/icon-burger--hover.png');

//         @include retina {
//                 background-image: url('../img/icons/icon-burger--hover@2x.png');
//         }
//     }
// }

// .icon-chevron {
//     width: 23px;
//     height: 43px;
// }

// .icon-chevron-left {
//     background-image: url('../img/icons/icon-chevron-left.png');

//     @include retina {
//         background-image: url('../img/icons/icon-chevron-left@2x.png');
//     }
// }

// .icon-chevron-right {
//     background-image: url('../img/icons/icon-chevron-right.png');

//     @include retina {
//         background-image: url('../img/icons/icon-chevron-right@2x.png');
//     }
// }

// .icon-twitter,
// .icon-facebook,
// .icon-rss {
//     width: 31px;
//     height: 31px;
// }

// .icon-twitter {
//     background-image: url('../img/icons/icon-twitter.png');

//     &:hover {
//         background-image: url('../img/icons/icon-twitter--hover.png');
//     }

//     @include retina {
//         background-image: url('../img/icons/icon-twitter@2x.png');

//         &:hover {
//             background-image: url('../img/icons/icon-twitter--hover@2x.png');
//         }
//     }
// }

// .icon-facebook {
//     background-image: url('../img/icons/icon-facebook.png');

//     &:hover {
//         background-image: url('../img/icons/icon-facebook--hover.png');
//     }

//     @include retina {
//         background-image: url('../img/icons/icon-facebook@2x.png');

//         &:hover {
//             background-image: url('../img/icons/icon-facebook--hover.png');
//         }
//     }
// }

// .icon-rss {
//     background-image: url('../img/icons/icon-rss.png');

//     &:hover {
//         background-image: url('../img/icons/icon-rss--hover.png');
//     }

//     @include retina {
//         background-image: url('../img/icons/icon-rss@2x.png');

//         &:hover {
//             background-image: url('../img/icons/icon-rss--hover@2x.png');
//         }
//     }
// }

/*=============================================================================
    logo
=============================================================================*/
.logo {
    @include ib;

    // background-size: 100%;
    // background-repeat: no-repeat;
}

// .logo-vtedy {
//     width: 257px;
//     height: 75px;

//     background-image: url('../img/logo-vtedy.png');

//     &:hover {
//         background-image: url('../img/logo-vtedy--hover.png');
//     }

//     @include retina {
//         background-image: url('../img/logo-vtedy@2x.png');

//         &:hover {
//             background-image: url('../img/logo-vtedy--hover@2x.png');
//         }
//     }
// }

// .logo-vtedy-2 {
//     width: 130px;
//     height: 38px;

//     background-image: url('../img/logo-vtedy-2.png');

//     @include retina {
//         background-image: url('../img/logo-vtedy-2@2x.png');
//     }
// }

// .logo-vtedy-3 {
//     width: 130px;
//     height: 38px;

//     background-image: url('../img/logo-vtedy-3.png');

//     @include retina {
//         background-image: url('../img/logo-vtedy-3@2x.png');
//     }
// }

// .logo-tasr {
//     width: 97px;
//     height: 18px;

//     background-image: url('../img/logo-tasr.svg');
//     background-position: 50% 40%;
//     background-size: 97px 18px;

//     .no-svg & {
//         background-image: url('../img/logo-tasr.png');
//     }
// }

/*=============================================================================
    buttons
=============================================================================*/
.btn {
    display: inline-block;
    zoom: 1;
    *display: inline;
    padding: 6px 15px 8px;
    border: 1px solid $black;
    border-radius: 3px;
    background: $pure-white;
    color: $black;
    font-size: 15px;
    line-height: 1.5;
    font-family: $fs-1;
    font-weight: $medium;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    transition: all 100ms ease-in-out;

    &:hover {
        background: $black;
        color: $pure-white;
        text-decoration: none;
    }
}

.btn--lg {
    padding: 5px 20px 7px;
    font-size: 20px;
}

.btn--full-width {
    width: 100%;
}

.btn--primary {
    border: none;
    background: $primary;

    &:hover {
        background: darken($primary, 12%);
    }
}

.btn--secondary {
    border: 1px solid $primary;
    background: $pure-white;
    color: $primary--darker;

    &:hover {
        background: $primary;
    }
}

.btn--tertiary {
    border: 1px solid $primary;
    background: none;
    color: $pure-white;

    &:hover {
        background: $primary;
        color: $black;
    }
}

.btn--ghost {
    background: none;
}

.btn--fb {
    width: 100%;
    padding: 7px 10px;
    max-width: 280px;
    border-radius: 5px;
    background: #3b5998;
    color: $pure-white;
    text-align: center;
    text-decoration: none;
    transition: all 150ms;

    &:hover {
        background: darken(#3b5998, 7%);
        text-decoration: underline;
    }
}

// label for input of type="file"
.btn--file {
    cursor: pointer;
}

/*=============================================================================
    form componenets - inputs. selects, textareas,...
=============================================================================*/

.form-group {
    margin: 0 0 10px;
}

.input,
.textarea,
.select {
    padding: 7px 13px;
    border: 1px solid $primary--light;
    border-radius: 3px;
    background: $pure-white;
    font-size: 15px;
    line-height: 1.5;
    color: $black;
    outline-color: $primary--dark;

    @include placeholder {
        color: #999999;
    }
}

.input--full-width,
.textarea--full-width,
.select--full-width {
    display: block;
    width: 100%;
}

.input,
.select {
    height: 38px;
}

.input {
    vertical-align: middle;
}

.input--two-chars,
.input--four-chars,
.input--month {
    text-align: center;
}

.input--two-chars {
    width: 45px;
}

.input--four-chars {
    width: 65px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.input--month {
    width: 105px;
}

.textarea {
    resize: none;
}

.select-wrapper {
    position: relative;

    &:after {
        content: '▾';
        position: absolute;
        top: 9px;
        right: 10px;
    }
}

.select {
    padding: 9px 26px 9px 13px;
    appearance: none;
}

/*=============================================================================
    radio
=============================================================================*/

.radio {
    line-height: 40px;
    cursor: pointer;
}

.radio__input {
    display: none;
}

.radio__label {}

.radio__indicator {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid $primary--lighter;
    border-radius: 50%;
    margin-right: 5px;
    background: $pure-white;
    vertical-align: middle;

    &:before {
        content: ' ';
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        display: inline-block;
        border-radius: 50%;
        background: transparent;
        transition: background 150ms;
    }
}

.radio__input:checked + .radio__indicator:before {
    background: $black;
}

/*=============================================================================
    toggle & switch
=============================================================================*/
.toggle {
    user-select: none;
}

$switch-width: 68px;
$switch-height: 26px;

.switch {
    position: relative;
    display: inline-block;
    width: $switch-width;
    height: $switch-height;
    border: 1px solid $black;
    border-radius: 26px;
    background: $white;
    vertical-align: middle;

    // overflow: hidden;
    user-select: none;
    cursor: pointer;
}

.switch__input {
    display: none;
}

.switch__label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 10px;
    border-radius: $switch-height;
    line-height: $switch-height - 2px;
    transition: all 150ms;
}

.switch__label-default {
    background: $gray--lightest;
    text-align: right;
    transition: all 300ms ease;
}

.switch__label-active {
    // display: none;
    opacity: 0;
    color: $white;
    background: $success;
}

.switch.is-active .switch__label-default,
.switch__input:checked ~ .switch__label-default {
    // display: none;
    opacity: 0;
}

.switch.is-active .switch__label-active,
.switch__input:checked ~ .switch__label-active {
    // display: inline;
    opacity: 1;
}

.switch__handle {
    position: absolute;
    top: -1px;
    left: -1px;
    width: $switch-height;
    height: $switch-height;
    border: 1px solid $black;
    border-radius: 50%;
    background: $white;
    transition: all 150ms ease-out;
}

.switch.is-active .switch__handle,
.switch__input:checked ~ .switch__handle {
    left: ($switch-width - $switch-height) -1px;
}

/*=============================================================================
    bg
=============================================================================*/
.bg-dark {
    background: $black;
}

.bg-light {
    background: $white;
}

/*=============================================================================
    colors
=============================================================================*/

.color-success {
    color: $success;
}

.color-error,
.color-warning {
    color: $error;
}

/*=============================================================================
    vertical alignment
=============================================================================*/
.va {
    display: table;
    width: 100%;
    height: 100%;
}

.va__inner {
    display: table-cell;
    vertical-align: middle;
}

/*=============================================================================
    media
=============================================================================*/
.media,
.media__main {
    overflow: hidden;
    _overflow: visible;
    zoom: 1;
}

.media__img {
    float: left;
    margin-right: 10px;

    > img {
        display: block;
    }
}

.media__img.right {
    float: right;
    margin-right: 0;
    margin-left: 10px;
}

/*=============================================================================
    tasr-bar
=============================================================================*/
.tasr-bar {
    padding: 0 10px;
    background: $pure-black;

    @include media-max($md) {
        display: none;
    }
}

.tasr-links {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
}

.tasr-links--other {}

.tasr-links--main {
    float: right;
}

.tasr-links__item {
    float: left;
    margin-left: 20px;

    &:first-child {
        margin-left: 0;
    }
}

.tasr-links__link {
    display: block;
    height: 36px;
    color: $white--darker;
    line-height: 36px;
    text-decoration: none;

    .logo-tasr {
        margin-top: 5px;
    }
}

/*=============================================================================
    menu-btn
=============================================================================*/
.menu-btn {
    padding: 15px;
    background: none;
    border: none;
}

.menu-btn .icon {
    display: block;
}

/*=============================================================================
    search
=============================================================================*/
.search-term {
    position: relative;
    display: inline-block;
    max-width: 100%;
    padding-right: 20px;
}

.search-term__heading {
    max-width: 100%;
    margin: 0;
    font-size: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include media-max($md) {
        font-size: 22px;
    }
}

.search-term__remove {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 5px;

    .icon {
        display: block;
    }
}

.search {}

.search-btn {
    width: 55px;
    height: 55px;
    padding: 0;
    border: none;
    background: none;

    .icon-active {
        display: none;
    }
}

.search-btn.is-active {
    .icon-default {
        display: none;
    }

    .icon-active {
        display: inline-block;
    }
}

.search__placeholder {
    @include ib;
}

.search__input {}

.search__submit {
    border: none;
    background: none;
}

.search__input,
.search__submit {
    vertical-align: middle;
}

.search--minimal {
    .search__input {
        border: none;
        border-bottom: 2px solid $primary;
        background: none;
        font-size: 22px;
        transition: 150ms all ease-in-out;

        &:focus {
            border-color: $primary;

            // background: rgba(0, 0, 0, 0.5);

            outline: none;
        }
    }
}

.search--basic {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 360px;
    height: 40px;
    padding-right: 40px;
    border: 1px solid $primary--lighter;
    border-radius: 3px;
    background: $pure-white;

    .search__input {
        width: 100%;
        height: 100%;
        border: none;
    }

    .search__submit {
        position: absolute;
        top: 0;
        right: 0;
        width: 38px;
        height: 100%;
        padding: 0;
        border: none;
    }
}

.search--header {
    $btn-width: 45px;

    position: relative;

    .search__input {
        padding-right: $btn-width + 15px;
    }

    .search__input.is-hidden {
        visibility: hidden;
        width: 0;
        padding-right: $btn-width;
    }

    .search__submit {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: $btn-width;

        .icon {
            // vertical-align: middle;
            vertical-align: -2px;
        }
    }
}

@include media-max($sm) {}


/*=============================================================================
    drag-n-drop
=============================================================================*/

.drag-n-drop {
    position: relative;
    height: 250px;
    border: 2px dashed $primary;
    border-radius: 3px;
    background: $pure-white;
    color: $primary;
    font-size: 13px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
}

.drag-n-drop__input {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    color: transparent;
    text-indent: -9999px;
    opacity: 0;
    cursor: pointer;
}

.drag-n-drop__btns {
    display: none;
}

.drag-n-drop__btn {
    margin: 3px 4px;
}

.drag-n-drop:hover {
    .drag-n-drop__text {
        display: none;
    }

    .drag-n-drop__btns {
        display: block;
    }
}

/*=============================================================================
    login-form
=============================================================================*/
.login-form {
    max-width: 300px;
    margin: 150px auto;
}

/*=============================================================================
    side-nav
=============================================================================*/
.side-nav {
    position: absolute;
    z-index: 1000;
    top: 0;
    height: 100%;
    border-right: 1px solid $secondary;
    background: $black;
}

.side-nav > ul,
.side-nav > ol {
    padding: 0;
    margin: 30px 0 0;
    list-style: none;
}

.side-nav > ul > li > a,
.side-nav > ol > li > a {
    display: block;
    padding: 15px 30px 15px 28px;
    border-left: 2px solid transparent;
    color: $white;
    text-decoration: none;

    &:hover {
        border-color: $primary;
        color: $primary;

        // text-decoration: underline;
   
    }
}

/*=============================================================================
    off-canvas
=============================================================================*/

.off-canvas-body {
    position: relative;
    left: 0;
    width: 100%;
    transition: all 150ms;
}

.off-canvas-body.nav-active {
    left: 20%;
    width: 80%;
    overflow-x: hidden;

    .csstransforms & {
        left: 0;
        transform: translateX(25%);
    }

    @include media-max($xl) {
        width: 100%;
    }


    @include media-max($md) {
        left: 40%;

        .csstransforms & {
            transform: translateX(40%);
        }
    }


    @include media-max($sm) {
        left: 60%;

        .csstransforms & {
            transform: translateX(60%);
        }
    }


    @include media-max($xs) {
        left: 75%;

        .csstransforms & {
            transform: translateX(75%);
        }
    }
}

.off-canvas-nav {
    left: -20%;
    width: 20%;
    box-shadow: none;
    transition: all 150ms;

    .csstransforms & {
        left: -25%; //25% of 80% is 20% of 100%!!
        width: 25%; //25% of 80% is 20% of 100%!!
   
    }

    @include media-max($md) {
        &,
        .csstransforms & {
            left: -40%;
            width: 40%;
        }
    }


    @include media-max($sm) {
        &,
        .csstransforms & {
            left: -60%;
            width: 60%;
        }
    }


    @include media-max($xs) {
        &,
        .csstransforms & {
            left: -75%;
            width: 75%;
        }
    }
}

.off-canvas-nav.is-active {
    box-shadow: 0 0 30px rgba(black, 0.8);
}

/*=============================================================================
    primary-nav
=============================================================================*/
.primary-nav {
    margin-left: 50px;
}

.primary-nav__list {
    > li {
        float: left;
        color: $primary;
        line-height: 30px;

        > a {
            display: block;
            padding: 5px 12px;
            color: inherit;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $pure-white;
                text-decoration: underline;
            }
        }
    }

    > li.is-active {
        color: $pure-white;
    }

    > li.is-highlighted {
        background: $red;
        color: $pure-white;
    }
}

@include media-max($md) {
    .primary-nav {
        margin-left: 0;
    }
}


/*=============================================================================
    secondary-nav
=============================================================================*/
.secondary-nav {
    > ul,
    > ol {
        > li {
            margin-top: 5px;

            &:first-child {
                margin-top: 0;
            }

            > a {
                position: relative;
                display: inline-block;
                padding: 7px 20px;

                // padding-left: 30px;
                // margin-left: -10px;

                color: $black;
                font-size: 15px;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $primary--dark;
                    text-decoration: underline;
                }
            }

            &.is-active > a {
                background: $black;
                color: $primary;

                &:hover,
                &:focus {
                    // color: $primary--dark;
                    text-decoration: underline;
                }
            }
        }
    }
}

/*=============================================================================
    tabbed-nav
=============================================================================*/
.tabbed-nav {}

.tabbed-nav__list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.tabbed-nav__item {
    float: left;
}

.tabbed-nav__link {
    display: block;
    padding: 5px 15px;
    color: $black;
    font-size: 15px;
    font-family: $fs-2;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.tabbed-nav__item.is-active .tabbed-nav__link {
    background: $black;
    color: $primary;
}

/*=============================================================================
    pagination
=============================================================================*/

.pagination {
    padding: 5px 5px;
    color: $black;
}

.pagination--border-top-bottom {
    border-top: 1px solid $primary--darker;
    border-bottom: 1px solid $primary--darker;
}

.pagination--border-top {
    border-bottom: 1px solid $primary--darker;
}

.pagination-border-bottom {
    border-bottom: 1px solid $primary--darker;
}

.pagination--light-borders {
    border-color: $primary;
}

.pagination__previous,
.pagination__next {
    display: block;
    width: 180px;
    margin-top: 2px;
    color: inherit;
    font-size: 15px;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &.is-disabled {
        color: #bdac83;
        pointer-events: none;
    }
}

.pagination__previous {
    float: left;
}

.pagination__next {
    float: right;
    text-align: right;
}

.pagination__numbers {
    overflow: hidden;
    text-align: center;

    > li {
        display: inline-block;
        font-size: 18px;
        line-height: 25px;
        font-family: $fs-2;

        > a {
            display: block;
            width: 25px;
            height: 25px;
            color: inherit;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    > li.is-active > a {
        background: $black;
        color: $primary;
    }
}

@include media-max($sm) {
    .pagination__previous,
    .pagination__next {
        width: 50%;
        margin: 0 0 10px;
    }

    .pagination__numbers {
        clear: both;
    }
}


@include media-max($xs) {
    .pagination__previous,
    .pagination__next {
        width: 100%;
        text-align: center;
    }

    .pagination__numbers {
        padding-top: 10px;
    }
}


.pagination-simple {
    display: inline-block;
    margin: 0 0 0 5px;
    line-height: 35px;
    vertical-align: top;
    list-style: none;

    li {
        float: left;
        height: 35px;

        &:first-child {
            margin-left: 0;
        }
    }

    a {
        display: block;
        height: 100%;
        padding: 0 4px;
    }

    span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
        vertical-align: middle;
        transition: all 150ms ease-in-out;
    }

    a:hover span {
        background: $primary--dark;
        transform: scale(1.4);
    }

    li.is-active span {
        width: 13px;
        height: 13px;
        background: $secondary;
    }
}

/*=============================================================================
    timeline
=============================================================================*/

.timeline__item,
.timelist__list > li {
    position: relative;
    float: left;
    margin-right: 20px;
    text-align: center;

    &:last-child {
        margin-right: 0;
    }
}

.timeline__list--years {
    // width: 75%;
}

.timeline__list--years .timeline__item,
.timeline__list--years > li {
    width: 50px;
    margin-right: 0;
}

.timeline__item > a,
.timeline__list > li > a {
    display: block;
    color: $primary--light;
    font-size: 17px;
    line-height: 39px;
    font-family: $fs-2;
    text-decoration: none;

    &:hover {
        color: $primary--dark;
    }
}

@include media-max($md) {
    .timeline__list--years {
        float: none;
        width: 1px;
        min-width: 100%;

        // white-space: nowrap;
        // overflow: hidden;

        // font-size: 0;
   
    }

    .timeline__list--years .timeline__item {
        float: none;

        // display: inline-block;

        // font-size: $default-font-size;
   
    }
}


/*=============================================================================
    timeline-picker
=============================================================================*/

.timeline-picker {
    position: absolute;
    z-index: 200;
    left: 50%;
    width: 100%;
    padding: 0;
    margin: 1px 0 0;
    list-style: none;
}

.timeline-picker__item {
    position: absolute;
    width: 20%;
    margin-left: -10%;
}

.timeline-picker__item:nth-child(2) {
    left: 20%;
}

.timeline-picker__item:nth-child(3) {
    left: 40%;
}

.timeline-picker__item:nth-child(4) {
    left: 60%;
}

.timeline-picker__item:nth-child(5) {
    left: 80%;
}

.timeline-picker__link {
    position: relative;
    display: block;
    height: 32px;
    line-height: 31px;
}

.timeline-picker__mark {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 3px;
    height: 14px;
    margin-left: -1.5px;
    border-radius: 0 0 7px 7px;
    background: $primary--dark;
    transition: all 150ms ease-in-out;
}

.timeline-picker__item:nth-child(1) .timeline-picker__mark {
    // background: $secondary;
}

.timeline-picker__link:hover .timeline-picker__mark,
.timeline-picker__item.is-active .timeline-picker__mark {
    height: 21px;
    background: $secondary;
    transform-origin: top;

    // transform: scaleY(1.2);
}

// .timeline-picker__item:nth-child(2) .timeline-picker__mark {
//     height: 30%;
// }
// .timeline-picker__item:nth-child(3) .timeline-picker__mark {
//     height: 60%;
// }
// .timeline-picker__item:nth-child(4) .timeline-picker__mark {
//     height: 50%;
// }
// .timeline-picker__item:nth-child(5) .timeline-picker__mark {
//     height: 70%;
// }

@include media-max($md) {
    .timeline-picker {
        display: none;
    }
}


/*=============================================================================
    photo
=============================================================================*/
.gallery {
    padding: 0 0 10px;
    margin-top: 50px;
    background: $white;
}

.gallery-header {
    position: relative;
    padding: 30px 70px 10px;
    text-align: center;
}

.gallery-header__back-wrapper {
    position: absolute;
    top: -17px;
    left: 0;
    width: 100%;
}

.gallery-header__back {
    height: 34px;
    padding: 0 15px;
    border: 2px solid $black;
    background: $white;
    color: $black;
    font-family: $fs-2;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
}

.gallery-header__title {
    margin: 0 0 15px;
    font-size: 40px;
}

.gallery-header__social {
    position: absolute;
    top: 25px;
    right: 0;
}

@include media-max($md) {
    .gallery-header__title {
        font-size: 32px;
    }
}


.gallery-photo {
    position: relative;
    margin: 0 -15px;
}

.gallery-controls {
    position: relative;
}

.gallery-control {
    position: absolute;
    z-index: 100;
    top: 50%;
    width: 66px;
    height: 66px;
    margin-top: -33px;
    background: rgba($black, 0.7);
    line-height: 63px;
    text-align: center;
    transition: 150ms all;

    &:hover,
    &:focus {
        background: rgba($black, 0.9);
    }

    .icon {
        vertical-align: middle;
    }
}

.gallery-control--previous {
    left: 0;
}

.gallery-control--next {
    right: 0;
}

.photo {
    position: relative;
    background: lighten($black, 5%);
}

.photo-img {
    display: block;
    max-width: 100%;

    // width: 1000px;
    margin: 0 auto;
}

.photo-box {
    position: absolute;
    right: 20px;
    bottom: 0;

    // width: 65%;
    width: 550px;
    padding: 15px 35px 15px 20px;
    background: rgba($white, 0.9);
    font-size: 14px;
    line-height: 1.74em;

    .heading {
        padding-right: 20px;
        margin: 0 0 10px;
        color: $primary--darker;
        font-size: 30px;
        line-height: 1em;
        font-family: $fs-1;
    }

    p {
        margin: 0;
        letter-spacing: 0.3px;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: rgba(black, 0.1);
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(black, 0.25);
        border-radius: 5px;
    }
}

.photo-box-text {
    max-height: 10.44em; //6 rows
    // max-height: 8.7em;
    padding-right: 5px;
    overflow: auto;
}

.photo-box__header {
    position: relative;
    padding: 10px 0 15px;
    line-height: 34px;
}

.photo-box-close,
.photo-box-show {
    position: absolute;
    width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    text-align: center;
    line-height: 0;

    .icon {}
}

.photo-box-close {
    top: 0;
    right: 0;
    background: none;

    .icon {
        vertical-align: middle;
    }
}

.photo-box-show {
    right: 20px;
    bottom: 0;
    display: none;
    background: rgba($white, 0.9);
}

@include media-max($sm) {
    .photo-box {
        position: relative;
        left: 0;
        width: 100%;
        padding-right: 20px;

        .heading {
            padding-right: 40px;
        }
    }

    .photo-box-close,
    .photo-box-show {
        display: none;
    }
}


.photo-text {
    padding: 0 80px 30px;
    font-size: 14px;
    line-height: 1.74em;

    .heading {
        margin: 0;
        color: $primary--darker;
        font-size: 52px;
        line-height: 1em;
        font-family: $fs-1;
    }

    p {
        letter-spacing: 0.3px;
    }
}

/*=============================================================================
    photo-cards
=============================================================================*/
.photo-cards {
    position: relative;
    padding: 0;
    margin: 0 -5px;
    font-size: 0;
    list-style: none;
}

.photo-card {
    display: inline-block;
    width: 50%;
    padding: 0 5px;
    margin-bottom: 10px;
    font-size: $default-font-size;
    vertical-align: top;

    &:nth-child(2n+3) {
        // clear: left;
   
    }
}

.photo-card--lg {
    width: 100%;
}

.photo-card__inner {
    position: relative;
    display: block;
    padding: 10px 10px 15px;
    background: $white;
    color: $black;
    text-decoration: none;

    .photo-year {
        position: absolute;
        top: 15px;
        left: 0;
    }
}

.photo-card__banner {
    margin-top: 10px;
}

.photo-card__carousel-wrapper {
    margin-bottom: -10px;
}

.photo-card__category {
    position: absolute;
    top: 20px;
    left: 0;
    padding: 5px 10px 5px 25px;
    background: $black;
    color: $primary;
    z-index: 100;
    font-family: $fs-2;
    font-size: 16px;
}

.photo-card__img {
    width: 100%;
}

.photo-card__text {
    padding: 10px 5px 0;
    font-size: 13px;
    line-height: 1.4615em;

    // p {
    //     margin-top: 0;

    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    // }
}

.photo-card__title {
    height: 3.75em;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary--darker;

    .flexbox & {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.photo-card--lg .photo-card__title {
    height: auto;
    font-size: 32px;
}

.photo-card__perex {
    margin: 7px 0 0;
}

@include media-max($xs) {
    .photo-card {
        width: 100%;
    }
}


/*=============================================================================
    sidebar-listing
=============================================================================*/
.sidebar-listing {}

.sidebar-listing__list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-listing__item {
    .media__img {
        width: 110px;
    }

    .media__main {
        padding: 7px 10px 7px 0;
    }

    .heading {
        max-height: 2.6em;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        line-height: 1.3em;
        font-weight: $medium;
        font-family: $fs-1;

        .flexbox & {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .year {
        font-size: 13px;
    }
}

.sidebar-listing__link {
    display: block;
    background: $primary--light;
    border-top: 1px solid $black;
    margin-bottom: 1px;
    color: $black;
    text-decoration: none;

    &:hover {
        background: $primary--lighter;
    }
}

.sidebar-listing--historic-calendar {
    .sidebar-listing__link {
        padding: 5px 10px;
    }
}

/*=============================================================================
    featured-listing
=============================================================================*/
.featured-listing {
    position: relative;
    padding: 10px 70px 10px 10px;
    background: $white;
}

.featured-listing__more-btn {
    position: absolute;
    top: 45px;
    right: 25px;
    display: inline-block;
}

.featured-listing__list {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
}

.featured-listing__item {
    float: left;
    width: 20%;
    padding: 0 5px;

    .heading {
        height: 3.6em;
        margin: 7px 0 0px;
        color: $primary--darker;
        font-size: 15px;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;

        .flexbox & {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

.featured-listing__link {
    color: $black;
    text-decoration: none;
}

/*=============================================================================
    module
=============================================================================*/
.module {
    margin: 30px 0;
}

.l-columns + .module {
    margin-top: 0;
}

.l-primary .module:first-of-type,
.l-sidebar .module:first-of-type {
    margin-top: 0;
}

@include media-min($md) {
    .l-primary .module:last-of-type,
    .l-sidebar .module:last-of-type {
        margin-bottom: 0;
    }
}


// .l-primary .module:last-child,
// .l-secondary .module:last-child {
//     margin-bottom: 0;
// }

.module-header {
    position: relative;
    padding: 0 15px 10px;
}

.module-header__title {
    margin: 0;
}

.module-header__close {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: none;
}

.module-header--centered {
    position: relative;
    text-align: center;

    .module-header__back {
        position: absolute;
        top: 20px;
        left: 10px;
    }

    @include media-max($sm) {
        text-align: left;

        .module-header__back {
            position: static;
            display: inline-block;
            margin-bottom: 10px;
        }

        .module-header__title {
            display: block;
        }
    }
}

.module-footer {
    padding-top: 15px;
}

.module--sidebar {
    .module-header {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.module--pl {
    padding-left: 40px;

    @include media-max($md) {
        padding-left: 0;
    }
}

.module--admin {
    .module-header {
        border-bottom: 1px solid $primary;
    }

    .module-header__title {
        display: inline-block;
        margin: 0 20px 0 0;
        vertical-align: middle;
    }

    .module-header__search {
        margin-right: 7px;
    }

    .module-footer {
        padding-top: 0;
        border-bottom: 1px solid $primary;
    }

    @include media-max($md) {
        margin-left: 0;
    }


    @include media-max($sm) {
        .module-header__title {
            display: block;
        }

        .module-header__search {
            margin: 10px 0 0;
        }
    }
}

.module--admin-system {
    .module-main {
        padding: 10px 15px;
        border-bottom: 1px solid $primary;
    }
}

.module--photos-insert {
    margin: 0;

    .module-main {
        padding: 0 15px;
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
    }

    .module-footer {
        padding: 0 10px;
        border-bottom: none;
    }

    .module-header__search {
        display: inline-block;
        margin-right: 7px;
    }

    .module-header__range {
        display: inline-block;
    }
}

.module--top-banner {
    margin-bottom: 0;
    text-align: center;
}

/*=============================================================================
    listing
=============================================================================*/
.listing {}

.listing-list {
    .listing-item.is-unpublished,
    .listing-item.is-disabled {
        background: #ffd7da;

        .title {
            color: $red;
        }
    }
}

.listing-item {
    .title {
        margin: 0;
        font-family: $fs-default;
    }

    .category,
    .label {
        color: $gray;
        font-size: 12px;
    }
}

.listing-list--admin-articles,
.listing-list--admin-photos {
    .listing-item {
        display: table;
        width: 100%;
        table-layout: fixed;
        padding: 10px 20px;
        border-bottom: 1px solid $primary;

        .value,
        .label {
            display: block;
        }

        .title {
            height: 2.4em;
            overflow: hidden;
            text-overflow: ellipsis;

            .flexbox & {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .media {
            overflow: visible;
        }

        .img {
            width: 100px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }

    .listing-item__col {
        display: table-cell;
        vertical-align: top;
    }

    .listing-item__id {
        width: 60px;
        color: $gray;
        font-size: 12px;
    }

    .listing-item__main {
        width: 100%;
        padding-right: 20px;
    }

    @include media-max($sm) {
        .listing-item {
            display: block;
            text-align: center;

            .img {
                float: none;
                margin: 10px auto 10px;
            }

            .title {
                height: auto;
            }
        }

        .listing-item__col {
            display: block;
            width: 100%;
            padding: 0;
            float: none;
            text-align: center;
        }
    }
}

/*  listing-list--admin-article
=============================================================================*/

.listing-list--admin-articles {
    .listing-item {
        .title {
            color: $primary--darker;
        }
    }

    .listing-item__readibility,
    .listing-item__status {
        width: 140px;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .listing-item__ui {
        width: 200px;
        text-align: right;
        white-space: nowrap;

        .btn + .btn {
            margin-left: 5px;
        }
    }

    @include media-max($md) {
        .listing-item__main {
            display: block;
        }

        .listing-item__readibility,
        .listing-item__status {
            float: left;
            width: 110px;
            margin-top: 20px;
        }
    }


    @include media-max($sm) {
        .listing-item__ui {
            margin: 20px 0 0;
        }
    }
}

/*  listing-list--admin-photos
=============================================================================*/
.listing-list--admin-photos {
    .listing-item {
        .title {
            // margin: 0.2em 0 0;
       
        }
    }
}

/*  listing-list--admin-categories
=============================================================================*/
.listing-list--admin-categories,
.listing-list--admin-users {
    .listing-item {
        display: table;
        width: 100%;
        padding: 5px 15px;
        border-bottom: 1px solid $primary;

        .title {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $primary--darker;
        }

        .label {
            color: $gray;
        }
    }

    .listing-item__col {
        display: table-cell;
        vertical-align: middle;
    }

    .listing-item__ui {
        text-align: right;
    }
}

.listing-list--admin-categories {
    .listing-item__main {
        width: 25%;
    }

    .listing-item__articles {
        width: 15%;
    }

    @include media-max($md) {
        .listing-item__main {
            width: 35%;
        }
    }


    @include media-max($sm) {
        .listing-item {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .listing-item__col {
            display: block;
            width: auto;
            text-align: center;
        }

        .listing-item__col + .listing-item__col {
            margin-top: 10px;
        }
    }
}

/*  listing-list--admin-users
=============================================================================*/
.listing-list--admin-users {
    .listing-item__main {
        width: 40%;
    }

    @include media-max($md) {
        .listing-item__main {
            width: 60%;
        }
    }


    @include media-max($sm) {
        .listing-item {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .listing-item__col {
            display: block;
            width: auto;
            text-align: center;
        }

        .listing-item__col + .listing-item__col {
            margin-top: 10px;
        }
    }
}

/*  listing-list--photos-insert
=============================================================================*/
.listing-list--photos-insert {
    margin: 0 -7px;

    .listing-item {
        float: left;
        width: 20%;
        padding: 0 4px;
        margin-bottom: 30px;
    }

    .listing-item.is-selected .listing-item__img {
        opacity: 0.2;
    }

    .listing-item__img {
        display: block;

        > img {
            display: block;
            margin: 0 auto;
        }
    }

    .listing-item__text {
        height: 3 * $default-line-height * 1em;
        padding: 0 5px;
        margin: 5px 0 10px;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;

        .flexbox & {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }

    .listing-item__btn {
        text-align: center;
    }
}

/*=============================================================================
    article
=============================================================================*/
.article {
    background: $white;
    padding: 30px 30px 60px 25px;
}

.article-category {
    display: inline-block;
    padding: 5px 10px 5px 25px;
    margin: 0 0 0 -25px;
    background: $black;
    color: $primary;
    font-size: 20px;
}

.article-title {
    // margin: 10px 0 15px;
    margin: 0.4em 0;
}

.article-subtitle {
    font-size: 20px;
}

.article-main {
    position: relative;
    padding: 0 0 50px 20px;
    border-left: 1px solid $primary;
    margin: 0 0 0 70px;
    font-size: 16px;

    p {
        margin: 1em 0;
        line-height: 1.571;
    }

    h2 {
        margin: 30px 0 5px;
    }

    h2 + p {
        margin-top: 0;
    }

    > img {
        margin: 1em 0;
    }
}

.article-figure {
    margin: 1em 0;
}

.article-figure__caption {
    font-style: italic;
}

// article-grid
//=============================================================================
.article-grid {
    margin: 1em -4px;
    font-size: 0;
}

.article-grid__item {
    display: inline-block;
    width: 4 / 12 * 100%;
    padding: 0 4px;
    font-size: $default-font-size;
    vertical-align: top;
}

.article-grid__item:nth-child(3) ~ .article-grid__item {
    margin-top: 8px;
}

.article-grid__link {
    position: relative;
    display: block;

    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        transition: background 150ms;
    }
}

.article-grid__link:hover:before {
    background: rgba(white, 0.5);
}

.article-grid__img {
    display: block;
}

.article-footer {
    margin: 30px 0 0;
    text-align: center;
}

.article-sharing {
    position: absolute;
    top: 0;
    left: -70px;
    width: 70px;
    padding: 0 10px 0 0;
    text-align: center;
}

/*=============================================================================
    edit-insert
=============================================================================*/
.edit-section {
    padding: 5px 10px;
    border-bottom: 1px solid $primary;
}

.edit-row {
    margin-top: 10px;
    margin-bottom: 10px;
}

.edit-label {
    // width: 100%;
    // max-width: 165px;
    // padding-right: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 40px;
}

.edit-label--nested {
    position: static;

    // max-width: 70px;
}

.edit-main {
    // padding-right: 260px;
    // padding-left: 165px;

    .radio + .radio {
        margin-left: 35px;
    }
}

.edit-main--nested {
    // padding: 0;
    overflow: hidden;
}

.edit-insert {
    position: relative;

    // margin: 10px 0;
}

.edit-insert + .edit-insert {
    margin-top: 10px;
}

.edit-insert__delete {
    position: absolute;
    top: 10px;
    right: -35px;
    width: 25px;
    height: 25px;
    padding: 0;
    border: none;
    background: none;

    &:hover,
    &:focus {
        background: $primary--lighter;
    }
}

.edit-insert-handle {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;

    // background: rgba(black, 0.08);
    cursor: move;
}

.ui-sortable.is-active .edit-insert-handle {
    display: block;
}

.edit-insert-handle__icon {
    position: absolute;
    top: 3px;
    left: 3px;
}

.edit-insert--photo {
    &.has-image {
        margin-bottom: 30px;
    }

    .edit-insert__photo {
        border: 1px solid $primary--lighter;
        border-radius: 3px;
        background: $pure-white;
        overflow: hidden;

        .drag-n-drop {
            margin: 20px 23px;
        }
    }
}

.edit-insert--gallery-photo {
    // margin-bottom: 20px;

    .edit-insert__photo {
        float: right;
        margin-left: 10px;
        width: 160px;

        // border: 1px solid $primary--lighter;

        overflow: hidden;

        .drag-n-drop {
            height: 130px;
        }
    }

    .edit-insert__main {
        overflow: hidden;

        .edit-row:first-child {
            margin-top: 0;
        }

        .edit-row:last-child {
            margin-bottom: 15px;
        }
    }
}

.edit-photos {
    padding: 10px 0;
    margin: 0 -285px 0 -230px;
    background: $primary;
}

.edit-insert-controls {
    margin: 10px 0;
}

.edit-insert-section {
    position: relative;
    z-index: 0;
    padding: 10px;
    border: 1px dashed $primary;
    border-radius: 3px;
    margin: 10px 0;
    background: $white;
}

.edit-insert-section.ui-sortable-helper {
    box-shadow: 0 0 16px rgba(black, 0.3);
}

.galleryPhotoWrapper {
    position: relative;
    padding: 10px;
    border: 1px dashed $primary;
    border-radius: 3px;
    margin: 10px 0;
}

.addGalleryPhotoWrapper {
    // margin: 10px;
}

.sortable-placeholder {
    border: 1px dashed $primary;
    border-radius: 3px;
    height: 80px;
    margin: 15px -5px;
    line-height: 1.2em;
}

.btn-reorder {
    // margin-left: 100px;
    //display: none;
}

.mjs-nestedSortable-error {
    border-color: red;
}

.edit-form-submit {
    margin: 30px 0 0;
    text-align: center;
}

.edit-form-cancel {
    float: right;
    margin: 0 0 10px;
}

/*=============================================================================
    about-project
=============================================================================*/
.about-project {
    float: right;
    padding-bottom: 30px;

    // margin-top: 60px;
    // margin-right: -10px;
    // margin-left: 550px;
    margin: 60px 0 0 150px;
    background: $white;
}

.about-project__header {
    padding: 30px 20px;
}

.about-project__aside {
    float: left;
    width: 220px;
    margin-top: 10px;
}

.about-project__main {
    // padding-right: 550px;
    // padding-bottom: 60px;
    // padding-left: 40px;
    padding: 0 170px 60px 40px;
    border-left: 1px solid $primary;
    font-size: 14px;
    overflow: hidden;

    .heading {
        margin: 0;
        font-size: 40px;
        line-height: 1.2em;
    }

    p {
        line-height: 1.57em;
        letter-spacing: 0.3px;
    }

    .quote {
        margin-right: -100px;
    }

    .quote__img {
        width: 120px;
        border-radius: 50%;
        overflow: hidden;
    }

    .quote__blockquote {
        margin: 15px 0 0 60px;
        font-size: 16px;
        font-style: italic;
    }

    .quote__author {
        margin: 0;
        text-align: right;
    }
}

@include media-max($md) {
    .about-project {
        margin-left: 0;
    }
}


@include media-max($sm) {
    .about-project__aside {
        float: none;
        border: none;
    }

    .about-project__main {
        padding: 0 60px 30px 20px;
        margin: 30px 0 0;

        .heading {
            font-size: 32px;
        }

        .quote {
            margin-right: -40px;
        }

        .quote__img {
            width: 90px;
        }

        .quote__blockquote {
            margin: 10px 0 0 20px;
        }
    }
}


@include media-max($xs) {
    .about-project__main {
        padding-right: 20px;

        .heading {
            font-size: 26px;
        }

        .quote {
            margin-right: 0;
        }

        .quote__img {
            float: none;
            margin: 0 auto;
        }
    }
}


/*=============================================================================
    social-links
=============================================================================*/
.social-links {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.social-links__item {
    @include ib;

    margin-left: 5px;

    &:first-child {
        margin-left: 0;
    }
}

/*=============================================================================
    admin
=============================================================================*/
.admin {
    min-width: 900px;
}

.admin-title {
    margin-bottom: 15px;
}

.admin-subtitle {
    margin: 15px 0 0;
    font-size: 19px;
}

/*=============================================================================
    admin-categories
=============================================================================*/
.admin-categories {
    margin: 0 -5px;
}

.admin-category {
    float: left;
    position: relative;
    width: 25%;
    padding: 0 5px;

    &:before {
        content: ' ';
        display: block;
        padding-bottom: 100%;
    }
}

.admin-category__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 5px;
}

.admin-category__link {
    height: 100%;
    background: $primary;
    text-align: center;

    &:hover,
    &:focus {
        background: darken($primary, 10%);
    }
}

@include media-max($sm) {
    .admin-category {
        width: 50%;
        margin-bottom: 10px;
    }
}


/*=============================================================================
    system
=============================================================================*/
.system {
    text-align: left;
}

.system__row {
    &:first-child .system__col {
        padding-top: 0;
    }

    &:last-child .system__col {
        padding-bottom: 0;
    }
}

.system__col {
    padding: 5px 0;
}

.system__header {
    padding-right: 25px;
    font-weight: normal;
}

/*=============================================================================
    footer
=============================================================================*/
.footer {
    text-align: center;
}

.footer__main {
    height: 140px;
    padding: 30px 0 0;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary--darkest;
    background: $black;
}

.footer__logo {
    position: absolute;
    left: 50%;

    @include ib;

    width: 320px;
    margin-left: -160px;
    text-align: center;

    p {
        position: relative;
        margin: 0;
        font-family: $fs-2;
    }

    p:before {
        content: ' ';
        display: block;
        width: 150px;
        height: 1px;
        margin: 5px auto 10px;
        background: $primary--dark;
    }
}

.footer__eu {
    float: left;
    text-align: left;

    p {
        float: left;
        width: 150px;
        margin: 0;
        font-size: 12px;
    }
}

.footer__links {
    float: right;
    margin-top: 10px;
}

.footer__copyright {
    padding: 20px 0 20px;
    border-top: 10px solid $black;
    color: $gray--dark;
    font-size: 11px;

    p {
        margin: 0;
    }

    a {
        @extend .link-default;

        color: #1a1a19;
    }
}

@include media-max($sm) {
    .footer {}

    .footer__main {
        height: auto;
    }

    .footer__logo {
        position: static;
        display: block;
        width: auto;
        margin: 0 auto 30px;
    }

    .footer__eu {
        float: none;
        margin: 0 auto 30px;
        text-align: center;

        .media__img {
            float: none;
            margin: 0 auto 10px;

            > img {
                display: inline-block;
            }
        }

        p {
            float: none;
            width: auto;
        }
    }

    .footer__links {
        float: none;
        margin: 0 auto 15px;
    }
}


/*=============================================================================
    adocean skyscraper
=============================================================================*/
.branding {
    position: absolute;

    /* for testing purposes */
    /*  width: 160px;
     height: 600px;
     background: red; */
}

@include media-max($lg) {
    .branding {
        display: none;
    }
}


.branding--left {
    right: 100%;
}

.branding--right {
    left: 100%;
}

/*========================================================
    Admin Statusbar
========================================================*/

.js-statusbar {
    position: fixed;
    top: 20vh;
    left: 25vw;
    z-index: 99;
    font-size: 1.3em;
    width: 50vw;
    background: white;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid black;
}
