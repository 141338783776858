/*=============================================================================
    grid
=============================================================================*/
.row {
    @extend .clearfix;
    display: block;
    margin: 0 -10px;
}

[class*="col-"] {
    display: block;
    float: left;
    padding: 0 10px;
}

@for $i from 1 through 12 {
    .col-#{$i} {
        width: ($i / 12 * 100%);
    }
}

@include media-max($lg) {
    @for $i from 1 through 12 {
        .col-lg-#{$i} {
            width: ($i / 12 * 100%);
        }
    }
}

@include media-max($md) {
    @for $i from 1 through 12 {
        .col-md-#{$i} {
            width: ($i / 12 * 100%);
        }
    }
}

@include media-max($sm) {
    @for $i from 1 through 12 {
        .col-sm-#{$i} {
            width: ($i / 12 * 100%);
        }
    }
}

@include media-max($xs) {
    @for $i from 1 through 12 {
        .col-xs-#{$i} {
            width: ($i / 12 * 100%);
        }
    }
}

/*=============================================================================
    header
=============================================================================*/
.header {
    background: $black;

    color: $white;
}

/*=============================================================================
    main
=============================================================================*/
.main {
    // background: $primary--dark;
    overflow-x: hidden;
}

.main > .container {
    position: relative;
}

/*=============================================================================
    site-ui
=============================================================================*/
.site-ui {

}

.site-ui__block {
    float: left;

    display: block;
}
.site-ui__block--right {
    float: right;
}

.site-ui__block--center {
    float: none;
    overflow: hidden;
}

.site-ui__logo {
    margin: 12px 35px 0 0;
    line-height: 1em;

    @include media-max($md) {
        margin: 8px 20px 0 0;
    }

    @include media-max($sm) {
        position: absolute;
        left: 50%;
        margin-left: -77.5px;
    }
}

.site-ui__search-term {
    padding-right: 20px;

    @include media-max($sm) {
        clear: both;
        margin: 0 0 15px;
        padding: 0;
        text-align: center;
    }
}

.site-ui__btn {
    float: right;
    line-height: 88px;

    @include media-max($md) {
        line-height: 52px;
    }
}


.site-ui-main {
    height: 92px;
    border-bottom: 1px solid $secondary;

    .menu-btn {
        margin: 20px 5px 0 -5px;
    }

    .copy {
        margin: 28px 30px 0 0;
    }

    .search-term {
        margin: 28px 0 0 ;
    }


    .search-btn {
        margin-top: 17px;
        margin-right: -10px;
    }

    .search {
        margin: 25px 0 0 0;
    }


    @include media-max($md) {
        height: 56px;

        .copy {
            margin: 7px 20px 0 0;
        }

        .menu-btn {
            margin: 0px 5px 0 -15px;
        }

        .search-term {
            margin-top: 13px;
        }

        .search {
            margin: 7px 0 0;
        }

        .search-btn {
            margin-top: 0;
        }
    }

    @include media-max($sm) {
        .search-term {
            margin-right: -20px;
        }
    }
}


.site-ui-search {
    display: none;
    padding: 10px 0;
}


.site-ui-secondary {
    position: relative;

    border-bottom: 1px solid $secondary;

    // color: $primary--light;
    // font-size: 17px;
    // line-height: 39px;
    // font-family: $fs-2;

    // ul {
    //     padding: 0;
    //     margin: 0;
    //     list-style: none;
    // }

    // ul > li {
    //     float: left;

    //     padding-right: 20px;

    //     &:last-child {
    //         padding-right: 0;
    //     }
    // }

    // ul a {
    //     display: block;

    //     color: $primary--light;
    //     line-height: 39px;

    //     text-decoration: none;

    //     &:hover {
    //         color: $primary--dark;
    //     }
    // }

    .timeline {
        margin-left: 55px;

        @include media-max($lg) {
            margin-left: 0;
        }
    }
}

/*=============================================================================
    welcomepage
=============================================================================*/

.l-columns {
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 30px 0;
}

.l-primary,
.l-sidebar {
    display: table-cell;
    // float: left;
    vertical-align: top;
    // padding: 10px 0;
}

.l-primary {
    width: 100%;
    // overflow: hidden;
    // width: calc(100% - 620px);
}

.l-sidebar {
    width: 310px;
}

.l-sidebar--right {
    // float: right;
    padding-left: 10px;
}

.l-sidebar--left {
    // float: left;
    padding-right: 10px;
}

@include media-max($md) {

    .l-columns {
        display: block;
        display: flex;
        flex-direction: column;
    }

    .l-primary,
    .l-sidebar {
        flex: 1 0 auto;
        display: block;
        width: 100%;
        padding: 0;
    }

    .l-sidebar--left {
        order: 2;
    }
}

/*=============================================================================
    l-admin
=============================================================================*/
.l-admin-categories {
    margin: 60px auto;
    width: 100%;
    max-width: 780px;
}


/*=============================================================================
    how-to-use
=============================================================================*/
.how-to-use {
    .heading {
        text-align: center;
    }

    .video {
        margin-bottom: 60px;
    }
}
/*=============================================================================
    footer
=============================================================================*/
$footer-height: 240px;

body {
    padding-bottom: $footer-height;
}

.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: $footer-height;
}

@include media-max($sm) {
    $footer-height: 350px;

    body {
        padding-bottom: #{$footer-height};
    }

    .footer {
        height: $footer-height;
    }
}

@include media-max($xs) {
    body {
        padding-bottom: 0;
    }

    .footer {
        position: static;
        height: auto;
    }
}

.footer {
    background: $primary--dark;

    color: $gray;
}