/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-burger-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -350px -110px;
  width: 29px;
  height: 25px;
}
.icon-burger {
  background-image: url(../img/sprites/sprite.png);
  background-position: -321px -110px;
  width: 29px;
  height: 25px;
}
.icon-chevron-left {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px -158px;
  width: 23px;
  height: 42px;
}
.icon-chevron-right {
  background-image: url(../img/sprites/sprite.png);
  background-position: -282px -158px;
  width: 23px;
  height: 42px;
}
.icon-chevron-xs-up {
  background-image: url(../img/sprites/sprite.png);
  background-position: -305px -189px;
  width: 16px;
  height: 9px;
}
.icon-close-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -367px -158px;
  width: 16px;
  height: 16px;
}
.icon-close-sm {
  background-image: url(../img/sprites/sprite.png);
  background-position: -367px -174px;
  width: 12px;
  height: 12px;
}
.icon-close-xs-red {
  background-image: url(../img/sprites/sprite.png);
  background-position: -217px -150px;
  width: 12px;
  height: 12px;
}
.icon-close-xs {
  background-image: url(../img/sprites/sprite.png);
  background-position: -205px -150px;
  width: 12px;
  height: 12px;
}
.icon-close {
  background-image: url(../img/sprites/sprite.png);
  background-position: -356px -138px;
  width: 16px;
  height: 16px;
}
.icon-facebook-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -352px -200px;
  width: 31px;
  height: 31px;
}
.icon-facebook {
  background-image: url(../img/sprites/sprite.png);
  background-position: -321px -200px;
  width: 31px;
  height: 31px;
}
.icon-move {
  background-image: url(../img/sprites/sprite.png);
  background-position: -372px -138px;
  width: 16px;
  height: 16px;
}
.icon-rss-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -290px -200px;
  width: 31px;
  height: 31px;
}
.icon-rss {
  background-image: url(../img/sprites/sprite.png);
  background-position: -336px -158px;
  width: 31px;
  height: 31px;
}
.icon-search-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -355px -76px;
  width: 34px;
  height: 34px;
}
.icon-search-sm-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -155px -150px;
  width: 25px;
  height: 26px;
}
.icon-search-sm {
  background-image: url(../img/sprites/sprite.png);
  background-position: -180px -150px;
  width: 25px;
  height: 26px;
}
.icon-search {
  background-image: url(../img/sprites/sprite.png);
  background-position: -321px -76px;
  width: 34px;
  height: 34px;
}
.icon-twitter-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: -305px -158px;
  width: 31px;
  height: 31px;
}
.icon-twitter {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px -200px;
  width: 31px;
  height: 31px;
}
.logo-eu {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px -76px;
  width: 62px;
  height: 62px;
}
.logo-tasr {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px -138px;
  width: 97px;
  height: 20px;
}
.logo-vtedy-2 {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px -38px;
  width: 131px;
  height: 38px;
}
.logo-vtedy-3 {
  background-image: url(../img/sprites/sprite.png);
  background-position: -259px 0px;
  width: 131px;
  height: 38px;
}
.logo-vtedy-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: 0px -75px;
  width: 259px;
  height: 75px;
}
.logo-vtedy-sm-hover {
  background-image: url(../img/sprites/sprite.png);
  background-position: 0px -150px;
  width: 155px;
  height: 45px;
}
.logo-vtedy-sm {
  background-image: url(../img/sprites/sprite.png);
  background-position: 0px -195px;
  width: 155px;
  height: 45px;
}
.logo-vtedy {
  background-image: url(../img/sprites/sprite.png);
  background-position: 0px 0px;
  width: 259px;
  height: 75px;
}

@media (-webkit-min-device-pixel-ratio: 2),
       (min-resolution: 192dpi) {
  .icon-burger-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-burger {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-chevron-left {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-chevron-right {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-chevron-xs-up {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-close-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-close-sm {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-close-xs-red {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-close-xs {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-close {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-facebook-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-facebook {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-move {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-rss-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-rss {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-search-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-search-sm-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-search-sm {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-search {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-twitter-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .icon-twitter {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-eu {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-tasr {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy-2 {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy-3 {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy-sm-hover {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy-sm {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
  .logo-vtedy {
    background-image: url(../img/sprites/sprite@2x.png);
    background-size: 390px 240px;
  }
}
