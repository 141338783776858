*,
*:before,
*:after {
    box-sizing: border-box;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}


html,
body {
    // height: 100%;

    background: $primary;
}

html {
    min-height: 100%;
    /* it messes up off canvas navigation */
    /* overflow-x: hidden; */
}

img {
    max-width: 100%;
}

button:focus,
.btn:focus,
input:focus,
.input:focus,
textarea:focus,
.textarea:focus,
select:focus,
.select:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(black, 0.15);
}

.container {
    // width: 1030px;
    width: 1200px;

    padding-right: 15px;
    padding-left: 15px;

    margin-right: auto;
    margin-left: auto;

    @include media-max($lg) {
        width: 100%;
    }

    // @include media-max($md) {
    //     width: 94%;
    // }

    // @include media-max($sm) {
    //     width: 100%;
    // }

}

.primary-c {
    color: $primary;
}
.primary--lighter-c {
    color: $primary--light;
}

.list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

.ta-center {
    text-align: center;
}

.ta-left {
    text-align: left;
}

.ta-right {
    text-align: right;
}