/* Generated by Font Squirrel (http://www.fontsquirrel.com) on December 5, 2015 */

@font-face {
    font-family: 'FuturaRound';
    src: url('../fonts/futuraround-medium-webfont.eot');
    src: url('../fonts/futuraround-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futuraround-medium-webfont.woff2') format('woff2'),
         url('../fonts/futuraround-medium-webfont.woff') format('woff'),
         url('../fonts/futuraround-medium-webfont.ttf') format('truetype'),
         url('../fonts/futuraround-medium-webfont.svg#futura_roundmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;

$fs-default: 'Open Sans', Verdana, Arial, sans-serif;
$fs-1: 'FuturaRound', 'Futura', 'Open Sans', Verdana, Arial, sans-serif;
$fs-2: 'Oswald', 'Futura Condensed', Verdana, Arial, sans-serif;

$default-font-size: 14px;
$default-line-height: 1.4;

html {
    color: $black;
    // font-size: 1em;
    font-size: $default-font-size;
    line-height: $default-line-height;

    font-family: $fs-default;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
    line-height: 1.2em;
    text-rendering: optimizeLegibility;
}

h1, .h1,
h2, .h2,
h3, .h3 {
    font-family: $fs-1;
}

h1, .h1 {
    font-size: 40px;
    font-weight: 500;
    font-family: $fs-1;
}
h2, .h2 {
    font-size: 25px;
    font-weight: 500;
    font-family: $fs-1;
}

h3, .h3 {
    font-size: 20px;
    line-height: 1.25;
    font-weight: 500;
}

h4, .h4 {
    font-size: 14px;
    font-weight: 400;
}

@include media-max($sm) {
    h1, .h1 {
        font-size: 32px;
    }
}


.fw-light {
    font-weight: 300;
}

.fw-normal {
    font-weight: 400;
}

.fw-medium {
    font-weight: 500;
}

.fw-bold {
    font-weight: 700;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

a {
    color: $primary--darker;
    text-decoration: underline;

    &:hover {
        color: $primary--darkest;
    }
}

.link {
    background: linear-gradient(to top, transparent 50%, $black 50%) 0 100% repeat-x;
    background-size: 2px 2px;

    color: inherit;
    font-weight: $bold;
    text-decoration: none;

    &:hover {
        background-image: linear-gradient(to top, transparent 50%, $primary--darker 50%);

        color: $primary--darker;
    }
}

.link-default {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

.link-primary {
    color: $primary--darker;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.link-reset {
    color: inherit;
    text-decoration: none;
}