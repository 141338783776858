/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Image replacement
 */

.ir {
    color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
}

/*
 * Hide visually and from screen readers:
 */

.hidden {
    display: none !important;
}


.hidden-lg {
    @include media-max($lg) {
        display: none !important;
    }
}

.hidden-md {
    @include media-max($md) {
        display: none !important;
    }
}

.hidden-sm {
    @include media-max($sm) {
        display: none !important;
    }
}

.hidden-xs {
    @include media-max($xs) {
        display: none !important;
    }
}

.visible-lg,
.visible-md,
.visible-sm,
.visible-xs,
.visible-lg-inline-block,
.visible-md-inline-block,
.visible-sm-inline-block,
.visible-xs-inline-block,
.visible-lg-inline,
.visible-md-inline,
.visible-sm-inline,
.visible-xs-inline {
    display: none !important;
}

.visible-lg {
    @include media-max($lg) {
        display: block !important;
    }
}
.visible-md {
    @include media-max($md) {
        display: block !important;
    }
}
.visible-sm {
    @include media-max($sm) {
        display: block !important;
    }
}
.visible-xs {
    @include media-max($xs) {
        display: block !important;
    }
}


.visible-lg-inline-block {
    @include media-max($lg) {
        display: inline-block !important;
    }
}
.visible-md-inline-block {
    @include media-max($md) {
        display: inline-block !important;
    }
}
.visible-sm-inline-block {
    @include media-max($sm) {
        display: inline-block !important;
    }
}
.visible-xs-inline-block {
    @include media-max($xs) {
        display: inline-block !important;
    }
}


.visible-lg-inline {
    @include media-max($lg) {
        display: inline !important;
    }
}
.visible-md-inline {
    @include media-max($md) {
        display: inline !important;
    }
}
.visible-sm-inline {
    @include media-max($sm) {
        display: inline !important;
    }
}
.visible-xs-inline {
    @include media-max($xs) {
        display: inline !important;
    }
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}