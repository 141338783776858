.owl-theme .owl-controls .owl-page span {
	position: relative;
    // background: $primary--dark;
    background: none;
    border: 2px solid $primary--dark;
    filter: none;
    opacity: 1;

    transition: background 150ms;

    .timeline & {
    	border-color: $primary;
    }
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
    background: $primary--dark;

    .timeline & {
    	background: $primary;
    }

    // &:before {
    // 	content: ' ';
    // 	position: absolute;
    // 	top: 3px;
    // 	right: 3px;
    // 	bottom: 3px;
    // 	left: 3px;
    // 	border-radius: 50%;
    // 	background: $primary--darkest;
    // }
}
